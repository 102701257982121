import { css } from "styled-components";

// CSS breakpoint values in pixels
const sm = 320;
const md = 400;
const lg = 700;
const xl = 1440;
const breakpoints = {
  xs: { min: 0, max: sm },
  sm: { min: sm + 1, max: md },
  md: { min: md + 1, max: lg },
  lg: { min: lg + 1, max: xl },
  xl: { min: xl + 1, max: 100000 }
};

/**
 * Remaps the provided object with the key values
 * set equal to the value returned from the callback function.
 * @param  {Object}   obj The object to remap. 'breakpoints' in this case.
 * @param  {Function} cb  The function to execute for a given key/value pair
 * @return {Object}       The remapped object
 */
function remap(obj, cb) {
  return Object.keys(obj).reduce(
    (acc, key) => ({
      ...acc,
      [key]: cb(key, obj[key])
    }),
    {}
  );
}

/**
 * Allows a developer to use the mixin 'breakpoint.lg.max`...css`'
 * in styled-components to set CSS breakpoints at the min-width,
 * max-width, or between the neighboring breakpoints.
 * @type {[type]}
 */
export const breakpoint = remap(breakpoints, (key, val) => ({
  min: (...cont) => css`
    @media (min-width: ${val.min}px) {
      ${css(...cont)}
    }
  `,
  max: (...cont) => css`
    @media (max-width: ${val.max}px) {
      ${css(...cont)}
    }
  `,
  only: (...cont) => css`
    @media (min-width: ${val.min}px) and (max-width: ${val.max}px) {
      ${css(...cont)}
    }
  `
}));
