import React from "react";
import H1 from "./H1";

function Header({ loading, error, name }) {
  if (loading) return <H1>Loading...</H1>;
  if (error) return <H1>Error loading collection</H1>;
  return <H1>Collection: {name}</H1>;
}

export default Header;
