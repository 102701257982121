import styled from "styled-components";
import { breakpoint } from "../../utils/styleMixins";

const Row = styled.div`
  padding: 15px 0;
  display: flex;
  flex-direction: column;
  border-bottom: 1px solid #d8d8d8;
  h2 {
    font-size: 20px;
    margin: 0 0 5px 0;
    font-weight: 600;
    color: black;
  }
  ${breakpoint.sm.max`
    padding: 10px 0;
    h2 {
      font-size: 16px;
    }
  `}
`;

export default Row;
