import styled from "styled-components";
import { breakpoint } from "../../utils/styleMixins";

const Container = styled.div`
  position: relative;
  bottom: 1px;
  width: 203px;
  height: 24px;
  ${breakpoint.sm.max`
    height: 20px;
    width: 164px;
    display: inline-flex;
    align-items: end;
  `}
  ${breakpoint.xs.max`
    margin-top: 10px;
  `}
`;

export default Container;
