import styled from "styled-components";

const Link = styled.a`
  text-decoration: none;
  &:hover {
    h2 {
      color: #0a587e;
      font-weight: 700;
    }
    .journal {
      font-weight: 600;
      font-style: italic;
    }
  }
`;

export default Link;
