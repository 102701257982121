import styled from "styled-components";
import { breakpoint } from "../../utils/styleMixins";

const RowMeta = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  color: #666666;
  font-size: 18px;
  .journal {
    font-style: italic;
  }
  ${breakpoint.sm.max`
    font-size: 14px;
  `}
`;

export default RowMeta;
