import styled from "styled-components";

const SeeMore = styled.div`
  color: #0d587e;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  font-weight: 600;
  a {
    color: inherit;
    text-decoration: none;
    span {
      &.text {
        text-decoration: underline;
      }
      &.arrow {
        position: relative;
        top: 1px;
      }
    }
  }
`;

export default SeeMore;
