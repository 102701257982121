import styled from "styled-components";
import { breakpoint } from "../../utils/styleMixins";

const Logo = styled.img`
  position: relative;
  top: 1px;
  margin-left: 2px;
  height: inherit;
  ${breakpoint.xs.max`
    margin-left: 4px;
  `}
`;

export default Logo;
