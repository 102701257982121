import React from "react";
import qxmd from "../../images/logo.svg";
import Container from "./Container";
import SeeMore from "./SeeMore";
import PoweredBy from "./PoweredBy";
import Logo from "./Logo";

function Footer({ url }) {
  return (
    <Container>
      <SeeMore>
        <a href={url} target="_blank" rel="noopener noreferrer">
          <span className="text">See More Papers</span>{" "}
          <span className="arrow">→</span>
        </a>
      </SeeMore>
      <PoweredBy>
        Powered by <Logo src={qxmd} alt="QxMD" />
      </PoweredBy>
    </Container>
  );
}

export default Footer;
