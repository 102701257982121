import React, { useEffect, useState } from "react";
import axios from "axios";
import Header from "../../components/Header";
import List from "../../components/List";
import Footer from "../../components/Footer";
import Container from "./Container";
import Separator from "./Separator";

function App() {
  const [appState, setAppState] = useState({
    loading: false,
    error: false,
    papers: [],
    collectionName: "",
    readUrl: ""
  });

  useEffect(() => {
    setAppState({ loading: true });
    const params = new URLSearchParams(window.location.search);
    const collection = parseInt(params.get("collection"), 10);
    if (!collection) {
      setAppState({ loading: false, error: true });
      return;
    }

    const apiUrl = `https://read-proxy-api.k8s.qxmd.com/v1/read/collection/${collection}?limit=5`;
    axios
      .get(apiUrl)
      .then(
        ({
          data: {
            data: {
              attributes: { name, papers, read_url }
            }
          }
        }) => {
          setAppState({
            loading: false,
            error: false,
            papers,
            collectionName: name,
            readUrl: read_url
          });
        }
      );
  }, [setAppState]);

  return (
    <Container>
      <Header
        loading={appState.loading}
        error={appState.error}
        name={appState.collectionName}
      />
      <Separator />
      <List papers={appState.papers} />
      <Footer url={appState.readUrl} />
    </Container>
  );
}

export default App;
