import React from "react";
import Container from "./Container";
import Link from "./Link";
import Row from "./Row";
import RowMeta from "./RowMeta";

function List({ papers }) {
  // Need title, link, date, journal name
  if (!papers) return null;
  const rows = papers.map(
    (
      {
        attributes: {
          articletitle,
          journaltitle,
          read_url,
          pubdate_year,
          pubdate_month,
          pubdate_day
        }
      },
      i
    ) => {
      // Format the publication date like "June 14, 2020"
      let date;
      if (pubdate_year && pubdate_month && pubdate_day) {
        date = new Date(
          pubdate_year,
          pubdate_month,
          pubdate_day
        ).toLocaleString("default", {
          month: "long",
          day: "numeric",
          year: "numeric"
        });
      } else if (pubdate_year && !pubdate_day) {
        date = pubdate_month
          ? new Date(pubdate_year, pubdate_month).toLocaleString("default", {
              month: "long",
              year: "numeric"
            })
          : pubdate_year;
      }

      return (
        <Link href={read_url} target="_blank" rel="noopener noreferrer" key={i}>
          <Row>
            <h2>{articletitle}</h2>
            <RowMeta>
              <span className="journal">{journaltitle}</span>
              <span>{date}</span>
            </RowMeta>
          </Row>
        </Link>
      );
    }
  );

  return <Container>{rows}</Container>;
}

export default List;
