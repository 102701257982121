import styled from "styled-components";
import { breakpoint } from "../../utils/styleMixins";

const Container = styled.div`
  padding-top: 15px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  font-size: 18px;
  ${breakpoint.sm.max`
    font-size: 14px;
  `}
  ${breakpoint.xs.max`
    flex-direction: column;
  `}
`;

export default Container;
